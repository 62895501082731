/* Chrome, Safari, Edge, Opera */
.hide-input-arrows::-webkit-outer-spin-button,
.hide-input-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hide-input-arrows[type='number'] {
  -moz-appearance: textfield;
  appearance: inherit;
}
